@-webkit-keyframes overlayAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes overlayAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.banner {
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  -o-object-fit: contain;
  object-fit: contain;
  background-position: center;
  position: relative;
  min-height: 580px;
  background-color: #141414;
  display: flex;
  flex-direction: column;
}

.banner__contents {
  margin-left: 4%;
  padding-top: 180px;
  /* height: 190px;*/
}

.banner__title {
  font-size: 3.2rem;
  margin: 0;
  margin-bottom: 1rem;
  font-weight: 700;
  width: 700px;
  max-width: 60%;
  text-transform: uppercase;
}
.banner__description {
  width: 45rem;
  line-height: 1.3;
  padding-top: 1rem;
  font-size: 1rem;
  max-width: 500px;
  height: 80px;
  font-weight: 400;
}
.banner__buttons {
  display: flex;
}
.banner__button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  text-transform: uppercase;
  border-radius: 0.2vw;
  padding: 0.5rem 1.6rem;
  margin-right: 2rem;
  background-color: #33333380;
  transition: all 0.3s;
}

.banner__button > .button__text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.banner__button:hover {
  color: #000;
  background-color: #e6e6e6;
}

.banner--fadeBottom {
  /*min-height: 7.4rem;*/
  min-height: 8rem;
  flex: 1;
  background-image: linear-gradient(180deg, transparent, #141414);
}

.banner__contentType {
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

.banner__contentType > span {
  color: #e50914;
}
.play {
  background-color: #e50914;
  color: white;
}

.play:hover {
  background-color: #f40612;
  color: white;
}
.model {
  position: fixed;
  padding-top: 8rem;
  padding-bottom: 4rem;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #000000e6;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.model .details {
  -webkit-animation: overlayAnimation 0.5s;
  animation: overlayAnimation 0.5s;
}
.model::-webkit-scrollbar {
  display: none;
}
/*.model .details__box > .details__content {
  padding-left: 20px;
}
*/
@media screen and (max-width: 1050px) {
  .banner__title {
    font-size: 3rem;
  }
}

@media screen and (max-width: 900px) {
  .banner {
    min-height: 460px;
  }
  .banner__contents {
    padding-top: 150px;
  }
  .banner__title {
    font-size: 2.6rem;
    width: 500px;
    max-width: 88%;
    margin-right: 2rem;
  }
  .banner__description {
    font-size: 0.9rem;
    width: 25rem;
    max-width: 80%;
  }
  .banner__contentType {
    font-size: 0.9rem;
  }
  .banner__buttons {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 500px) {
  .model {
    padding-top: 6rem;
  }
  /* .banner--fadeBottom {
    min-height: 6.4rem;
  }*/
  .banner__title {
    font-size: 2.25rem;
    width: auto;
  }
  .banner__contentType {
    font-size: 0.8rem;
  }
  .banner__description {
    font-size: 0.8rem;
    width: 290px;
  }
  .banner__button {
    margin-right: 20px;
    padding: 0.45rem 1.2rem;
    font-size: 0.88rem;
  }
  .banner__button .MuiSvgIcon-root {
    font-size: inherit;
  }
  .model .details__box > .details__content {
    padding-left: 0.8rem;
  }
}

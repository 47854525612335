.signIn {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.5) 20%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../../../Images/landingPage.jpg");
  background-position: center;
  background-size: cover;
  text-align: center;
  position: relative;
  color: white;
}
.signIn .logo {
  position: absolute;
  top: 1.5rem;
  left: 3%;
  width: 170px;
  -o-object-fit: contain;
  object-fit: contain;
}

.signin__container {
  max-width: 450px;
  margin-top: 6rem;
  margin-bottom: 3.8rem;
  background-color: #000000bf;
  border: 0.5px solid #3d3d3d80;
  text-align: left;
  padding: 3rem 4rem;
  padding-bottom: 9rem;
  border-radius: 3px;
  width: 100%;
}
.signin__container > form {
  display: flex;
  flex-direction: column;
}
.signin__container > form > input {
  margin: 0.5rem 0;
  height: 50px;
  background-color: #303030;
  padding: 10px 20px;
  border-radius: 5px;
  outline: none;
  border: none;
  color: white;
}
.signin__container > form > input:focus {
  border-bottom: 2px solid #e87c03;
}

.signin__container > form > button {
  padding: 0.9rem;
  margin: 2rem 0;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 3rem;
}
.signin__container > form > button:hover {
  background-color: #f40612;
}
.signin__container > p {
  font-size: 0.8rem;
  color: #8c8c8c;
  margin: 0.8rem 0;
}

.signin__container > h3 {
  font-weight: 400;
  font-size: 1rem;
  color: #8c8c8c;
  padding: 5px 0;
}
.signin__container h3 > a {
  color: white;
  text-decoration: none;
}
.signin__container h3 > a:hover,
.signin__container h3 > a:visited {
  text-decoration: underline;
}

.signin__container .signin__google {
  display: flex;
  color: white;
  cursor: pointer;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.signin__container .signin__google:hover {
  text-decoration: underline;
}
.signin__container .signin__google > img {
  width: 20px;
  margin-left: 10px;
  -o-object-fit: contain;
  object-fit: contain;
}

@media screen and (max-width: 740px) {
  .signin__container {
    max-width: 100%;
    height: 100%;
    margin: 0;
    background-color: black;
    padding: 5rem 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
    border: none;
  }
  .signIn .logo {
    width: 100px;
  }
}

@media screen and (max-width: 500px) {
  .signin__container {
    padding: 4.5rem 5%;
  }
}

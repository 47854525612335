.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: black;
}

.loading svg {
  width: 120px;
  height: 120px;
}

@media screen and (max-width: 800px) {
  .loading svg {
    width: 100px;
    height: 100px;
  }
}

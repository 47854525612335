.app {
  background-color: #141414;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
button,
a {
  cursor: pointer;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

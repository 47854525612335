* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

html {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
}
html::-webkit-scrollbar {
  display: none;
}

body {
  text-rendering: optimizeLegibility;
  font-family: -apple-system, BlinkMacSystemFont, "Kanit",sans-serif;
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141414;
  min-width: 320px;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
